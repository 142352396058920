module directives {
    export module sales {
        interface ISalesCreateNewVersionScope extends ng.IScope {
            soId: number;
            ngDisabled: boolean;
            showCreateNewVersion(): void;
        }

        export class salesCreateNewVersionDirective implements ng.IDirective {
            template = `<button type="button" class="btn btn-primary" ng-click="showCreateNewVersion()" ng-disabled="ngDisabled">Create New Version</button>
                        `;
            scope = {
                soId: "=",
                ngDisabled: "="
            }

           
            Reason: string;

            constructor(private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService, private salesOrderService: interfaces.sales.ISalesOrderService, private $state: ng.ui.IStateService
            ) {  }

            link = ($scope: ISalesCreateNewVersionScope, $element: ng.IAugmentedJQuery,$state) => {
                $scope.showCreateNewVersion = () => {
                    this.createNewVersion($scope.soId);
                }
            }

            //Display Create New Version Popup
            createNewVersion(soId: number) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="salesCreateNewVersion">
                                <div class="gts-padding">
                                    <fieldset id="createNew">
                                    <legend>
                                        Create New Version
                                    </legend>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Reason: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': reason.$invalid}">
                                                        <textarea class="form-control" type="text" ng-model="Reason" required="true"
                                                               aria-label="Reason" name="reason"></textarea>
                                                    </p>
                                                </div>                                               
                                            </div>                                     
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary" ng-disabled="salesCreateNewVersion.$invalid" ng-click="amend()">Amendment</button>
                                                    <button type="button" class="btn btn-warning" ng-disabled="salesCreateNewVersion.$invalid"  ng-click="cancellation()">Cancellation</button>
                                                    <button type="button" class="btn btn-info" ng-click="ok()">Cancel</button>
                                            </div>
                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, items) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.amend = () => {
                            this.submit(items.soId, 4, $scope.Reason)
                            $scope.ok();
                        }

                        $scope.cancellation = () => {
                            this.submit(items.soId, 4, $scope.Reason)
                            $scope.ok();
                        }
                    },
                    size: "md",
                    resolve: {
                        items: {
                            soId: soId
                        }

                    }
                }).closed.then( () => {
                   this.$state.reload();
                })
            };s

            submit(id: number,functionClassificationValueId : number,reason : string) {
                //Set the address.
                this.salesOrderService.CreateNewVersion().save(
                    { id, functionClassificationValueId, reason },
                     () => {
                        //Result
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, salesOrderService,$state) => new salesCreateNewVersionDirective(generalService, $uibModal,salesOrderService,$state);
                directive.$inject = ["generalService", "$uibModal", "salesOrderService","$state"];

                return directive;
            }
        }

        angular.module("app").directive("gtsSalesCreateNewVersion", salesCreateNewVersionDirective.factory());
    }
}